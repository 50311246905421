<template>
  <div class="help">
    <div class="page-body">
      <TitleBar :title="$t('help_center')" />
      <v-container>
        <v-row>
          <v-col>
            <h1 class="text-center">{{ $t("intro") }}</h1>
            <img
              alt="QQ Pay"
              class="text-center logo_img m-auto"
              src="../../assets/support.gif"
            />
            <div v-for="setting in settings" :key="setting.heading">
              <v-card :header="setting.heading" header-tag="header">
                <div>
                  <div class="d-flex float-end mb-2">
                    <v-btn
                      text
                      class="text-capitalize primary--text"
                      @click="contact_us = true"
                      ><v-icon class="pe-1">mdi-account-tie</v-icon>
                      {{ $t("customer_support") }}
                    </v-btn>
                  </div>
                  <v-card-text>
                    <p class="mt-4" v-for="data in setting.data" :key="data.id">
                      <span class="font-size-p font-weight-bold">
                        {{ data.title }}</span
                      >
                      <br />
                      <span class="font-size-ans">{{ data.text }}</span>
                    </p>
                  </v-card-text>
                  <br />
                </div>
              </v-card>
            </div>
          </v-col>
          <v-dialog v-model="contact_us" width="350">
            <v-card rounded="xl" class="mx-auto">
              <v-card-text class="pa-4 d-flex flex-column align-items-center">
                <div class="mt-4">
                  <v-icon
                    color="primary"
                    large
                    class="account-icons m-auto text-center"
                    style="border: 2px solid #ff6b00"
                  >
                    mdi-account-tie
                  </v-icon>
                </div>
                <p class="display-1 primary--text m-auto text-center">
                  {{ $t("contact_us") }}
                </p>
                <p class="text-center text-subtitle-2">
                  {{ $t("customer_support_text") }}
                </p>
                <div>
                  <p>
                    <v-icon>mdi-mail</v-icon> {{ $t("support_email") }}:
                    <b class="primary--text">support@qqpay.io</b>
                  </p>
                  <p>
                    <v-icon>mdi-phone</v-icon> {{ $t("support_phone") }}:
                    <b class="primary--text">010-2573947</b>
                  </p>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
// import NavBar from "@/components/navigations/NavBar.vue";
import TitleBar from "@/components/navigations/TitleBar.vue";
// import NavBarLeft from "@/components/navigations/NavBarLeft.vue";

export default {
  name: "Help",
  components: {
    // NavBar,
    TitleBar,
    // NavBarLeft
  },
  data() {
    return {
      contact_us: false,
      settings: [
        {
          heading: this.$t("faq"),
          data: [
            {
              id: 1,
              title: this.$t("ques"),
              text: this.$t("ans"),
            },
            {
              id: 2,
              title: this.$t("ques"),
              text: this.$t("ans"),
              value: true,
            },
            {
              id: 3,
              title: this.$t("ques"),
              text: this.$t("ans"),
              value: true,
            },
          ],
        },
        // {
        //   heading: this.$t("contact"),
        //   data: [
        //     {
        //       id: 1,
        //       title: this.$t("email"),
        //       text: "support@qqpay.com",
        //       value: true,
        //     },
        //     {
        //       id: 2,
        //       title: this.$t("helpLine"),
        //       text: "1800 1241 45",
        //       value: true,
        //     },
        //   ],
        // },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/global.scss";
h1 {
  color: $txt;
}
.logo_img {
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.font-size-p {
  font-size: 16px;
}
.font-size-ans {
  font-size: 14px;
}
::v-deep .custom-control-input {
  &:checked ~ .custom-control-label {
    &::before {
      color: #fff;
      border-color: $green !important;
      background-color: $green !important;
    }
  }
}
</style>
